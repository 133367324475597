<template>
  <div v-if="data?.statistieken">
    <UITableResponsive :items="data.statistieken || []" class="text-center w-auto" :headers="headers">
      <template #colgroup>
        <colgroup>
          <col class="w-0">
          <col class="w-0">
          <col class="w-28">
          <col class="w-28">
          <col class="w-28">
          <col class="w-auto">
        </colgroup>
      </template>
      <template #item-Datum="{ item }">
        <div class="flex flex-row gap-1">
          <span v-if="item.day">{{ item.day }}</span>
          <span v-if="item.month">{{ monthToString(item.month) }}</span>
          <span v-if="item.year">{{ item.year }}</span>
        </div>
      </template>
      <template #item-Omzet="{ item }">
        &euro;&nbsp;{{ formatPrice(item.omzet) }}
      </template>
      <template #item-Uitgevoerd="{ item }">
        &euro;&nbsp;{{ formatPrice(item.uitgevoerd) }}
      </template>
      <template #item-Commisie="{ item }">
        &euro;&nbsp;{{ formatPrice(item.commisie || item.uitgevoerd_commisie) }}
      </template>
      <template #item-Betaald="{ item }">
        &euro;&nbsp;{{ formatPrice(item.betaald) }}
      </template>
    </UITableResponsive>
  </div>
</template>

<script setup>
import UITableResponsive from '@/components/UI/Table/Responsive'

import { monthToString } from '@/functions/formatDate'
import { formatPrice } from '@/functions/formatNumber'
import useGetApi from '@/hooks/useGetApi'

const props = defineProps({
  filters: {
    type: Object,
    required: true,
  },
})

const headers = ['Datum', 'Email', 'Omzet', 'Uitgevoerd', 'Commisie', 'Betaald']

const { data } = useGetApi('/api/dashboard/stats/affiliates/sales', props.filters, { watch: true })
</script>
